<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card no-body>
              <b-card-body>
                <b-row class="border-bottom">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          :label="i18nT(`Title`)"
                          label-for="title"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              id="title"
                              v-model="document.Label"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div v-if="type !== 'Freelancer'">
                      <b-row>
                        <b-col
                          cols="12"
                          md="12"
                        >
                          <b-form-checkbox
                            name="check-button"
                            switch
                            :checked="document.Template"
                            v-model="document.Template"
                          >
                            {{i18nT(`Save as template`)}}
                          </b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-alert
                        class="mt-1"
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                          <span>
                            <b-badge variant="primary">
                              {{i18nT(`Info`)}}
                            </b-badge>
                            {{i18nT(`You can save common documents as templates for later use. You will find them in Document templates.`)}}
                          </span>
                        </div>
                      </b-alert>
                    </div>
                    <b-row v-if="displayExtentControls">
                      <b-col cols="6">
                        <b-form-group
                          :label="i18nT(`Document type`)"
                          label-for="Type"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Document Type`)"
                            rules="required"
                          >
                            <b-form-select
                              v-model="document.Type"
                              :options="documentTypes"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          :label="i18nT(`Put in document groups`)"
                          label-for="DocumentGroups"
                        >
                          <v-select
                            v-model="document.DocumentGroups"
                            :options="documentGroups"
                            multiple
                            label="text"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                        v-if="displayExtentControls"
                      >
                        <b-form-group
                          :label="i18nT(`Assign to`)"
                          label-for="AssignedTo"
                        >
                          <v-select
                            v-model="document.AssignedTo"
                            :options="assigneeFilter"
                            label="text"
                            multiple
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-checkbox
                          name="check-button"
                          switch
                          v-model="document.Share"
                        >
                        {{i18nT(`Share the document with assigned people`)}}
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-alert
                      class="mt-1"
                      variant="primary"
                      show
                    >
                      <div class="alert-body">
                        <span>
                          <b-badge variant="primary">
                            {{i18nT(`Info`)}}
                          </b-badge>
                          {{i18nT(`Shared documents with employees are visible in their accounts. You can restrict the access at any time.`)}}
                        </span>
                      </div>
                    </b-alert>
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group :label="i18nT(`Specific to software module`)">
                          <v-select
                            v-model="Software"
                            multiple
                            label="title"
                            :options="softwares"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            rows="3"
                            v-model="document.Description"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="pt-2 pb-2">
                  <b-col
                    cols="12"
                    md="12"
                  >
                    <b-tabs>
                      <b-tab :active="hasFile">
                        <template #title>
                          <feather-icon
                            icon="UploadCloudIcon"
                            size="18"
                            style="margin-right: 8px;"
                          />
                          <span>{{i18nT(`File`)}}</span>
                        </template>
                        <div>
                          <div v-if="document.AttachedFile">
                            <b-link :href="document.AttachedFile.DownloadUrl">{{document.AttachedFile.Label}}</b-link>
                          </div>

                          <div v-else>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="mr-1 mb-2"
                              @click="onFilePick"
                            >
                              <feather-icon icon="PlusCircleIcon" />
                              {{i18nT(`Add file`)}}
                            </b-button>
                            <b-form-file
                              v-model="file"
                              ref="filePicker"
                              class="hidden"
                            />
                            <span v-if="file">
                              {{fileName}}
                              <feather-icon
                                icon="Trash2Icon"
                                size="16"
                                style="margin-right: 8px;"
                                @click="onFileDelete"
                              />
                            </span>
                            <span v-if="!file">
                              {{originalFileName}}
                            </span>
                            <p>{{i18nT(`Attach images, text files, spreadsheets, pdf documents and videos.`)}} </p>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab :active="hasUrl">
                        <template #title>
                          <feather-icon
                            icon="LinkIcon"
                            size="18"
                            style="margin-right: 8px;"
                          />
                          <span>{{i18nT(`URL link`)}}</span>
                        </template>
                        <div>
                          <b-input-group>
                            <template #prepend>
                              <b-input-group-text>
                                <feather-icon
                                  icon="LinkIcon"
                                  size="18"
                                />
                              </b-input-group-text>
                            </template>
                            <b-form-input
                              :placeholder="i18nT(`URL`)"
                              v-model="document.Url"
                            />
                          </b-input-group>
                          <p class="pt-2">{{i18nT(`Enter a link to images, text files, spreadsheets, pdf documents and videos.`)}} </p>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
                <b-row>
                  <div style="flex: 1" />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-0 mt-md-2 ml-1 mr-1"
                    @click.prevent="validationForm"
                  >
                  {{i18nT(`Save`)}}
                  </b-button>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </div>

</template>

<script>
import {
  BButton,
  BAlert,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormCheckbox,
  BFormTextarea,
  BCardBody,
  BFormSelect,
  VBModal,
  BTabs,
  BTab,
  BFormFile,
  BInputGroup,
  BInputGroupText,
  BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { dictToSelectArray } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import _ from 'lodash'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,
    BFormSelect,
    BAlert,
    BBadge,
    BFormCheckbox,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BInputGroup,
    BInputGroupText,
    BLink
  },
  directives: {
    Ripple,
    'b-modal': VBModal
  },
  props: {
    existingDoc: {
      type: Object,
      required: false
    },
    onDocumentCreatedOrUpdated: {
      type: Function,
      required: true
    }
  },
  data() {
    const displayExtentControls = useAuth.getCurrentSoftware() != 'ca'
    return {
      document: {
        Template: 1
      },
      Software: [],
      documentTypes: [],
      documentGroups: [],
      assigneeFilter: [],
      file: null,
      fileName: null,
      required,
      email,
      displayExtentControls,
      type: useAuth.getUserType(),
      hasUrl: false,
      hasFile: false,
      originalFileName: null
    }
  },
  watch: {
    file: function(val) {
      if (val) {
        this.file = val
        this.fileName = val.name
      } else {
        this.file = null
        this.fileName = ''
      }
    }
  },
  created() {
    if (this.existingDoc) this.document = this.existingDoc
    this.document.Template = false
    this.softwares = _.values(useAuth.getSoftwares()).map(software => ({
      value: software.id,
      title: `${software.short.toUpperCase()} - ${software.title}`
    }))
    Promise.all([
      new Promise(res => {
        this.$http.get(`documents/documentTypes`).then(({ data }) => {
          const types = data.data.map(type => ({
            value: type.Id,
            text: type.Label
          }))

          this.documentTypes = types
          res()
        })
      }),
      new Promise(res => {
        this.$http.get(`documents/documentGroups`).then(({ data }) => {
          const documentGroups = data.data.document_groups.map(type => ({
            value: type.Id,
            text: type.Label
          }))

          this.documentGroups = documentGroups
          res()
        })
      }),
      this.type != 'Freelancer' &&
        new Promise(res => {
          this.$http.get(`documents/assignToUsersList`).then(({ data }) => {
            this.assigneeFilter = dictToSelectArray(data.data.users_list)
            res()
          })
        })
    ]).then(() => {})
  },
  computed: {},
  methods: {
    onFilePick: function() {
      this.$refs['filePicker'].$refs.input.click()
    },
    onFileDelete: function() {
      this.file = null
      this.fileName = ''
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.file && !this.document.Url && !this.document.AttachedFile) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Missing file`,
                icon: 'InfoIcon',
                variant: 'danger'
              }
            })
            return
          }

          // eslint-disable-next-line
          const formData = new FormData()
          if (this.document.Id) formData.append('id', this.document.Id)
          formData.append('Label', this.document.Label)
          formData.append('Type', this.document.Type)
          formData.append('Description', this.document.Description)
          if (this.document.AssignedTo) {
            formData.append(
              'assigned_to',
              this.document.AssignedTo.map(assignee => assignee.value).join(',')
            )
          }
          if (this.document.DocumentGroups) {
            formData.append(
              'document_groups',
              this.document.DocumentGroups.map(group => group.value).join(',')
            )
          }
          formData.append(
            'assigned_softs',
            this.Software.map(document => document.value).join(',')
          )
          if (this.file) {
            formData.append('fileUp', this.file)
          }

          if (this.type !== 'Freelancer') {
            formData.append('ShareWithFreelancer', this.document.Share)
            formData.append('IsTemplate', this.document.Template ? 1 : 0)
          } else {
            formData.append('ShareWithClient', this.document.Share)
          }

          const selectedSofware = useAuth.getCurrentSoftware()
          formData.append('Soft1', selectedSofware === 're' ? 1 : 0)
          formData.append('Soft2', selectedSofware === 'on' ? 1 : 0)
          formData.append('Soft3', selectedSofware === 'hr' ? 1 : 0)
          formData.append('Soft4', selectedSofware === 'en' ? 1 : 0)

          formData.append('Url', this.document.Url)
          this.$http
            .post('documents', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })
              this.onDocumentCreatedOrUpdated(res, !!this.existingDoc)
              // router.push({name: router.currentRoute.meta.template === 1 ? 'document-templates': 'documents'})
            })
            .catch(err => {
              console.log(err)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(err),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    },
    onSave() {
      console.log('Saved!!!')
    },
    onDelete() {
      this.$swal({
        title: 'Are you sure you want to delete this entry?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          console.log('Deleted!!!')
        }
      })
    },
    setup() {
      const vm = getCurrentInstance()
      console.log(vm)
      return { vm }
    }
  }
}
</script>

<style lang="scss">
.section-title {
  font-size: 11px;
}
.document-name {
  display: inline;
  line-height: 32px;
}
.document-actions-bar {
  float: right;
}
</style>
