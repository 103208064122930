<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col
                        cols="12"
                        md="12"
                    >
                        <b-card no-body>
                            <b-card-header>
                                <h4 v-if="!isTemplate" class="card-title">{{ i18nT(`Task`) }}</h4>
                                <h4 v-else class="card-title">{{ i18nT(`Task template`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row class="border-bottom">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{ i18nT(`Task information`) }}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-row>
                                            <b-col>
                                                <b-form-group
                                                    :label="i18nT(`Title`)"
                                                    label-for="title"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Title`)"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            id="title"
                                                            v-model="task.Label"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="12"
                                                class="mb-1"
                                            >
                                                <b-form-checkbox
                                                    v-if="!isTemplate"
                                                    name="check-button"
                                                    switch
                                                    :checked="task.Template"
                                                    v-model="task.Template"
                                                >
                                                    {{ i18nT(`Save as template`) }}
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-alert
                                            class="mt-1"
                                            variant="primary"
                                            show
                                        >
                                            <div class="alert-body">
                                                <span>
                                                  <b-badge variant="primary">
                                                    {{ i18nT(`Info`) }}
                                                  </b-badge>
                                                  {{ i18nT(`You can save common tasks as templates for later use. You will find them in Task templates.`) }}
                                                </span>
                                            </div>
                                        </b-alert>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Assign to`)"
                                                    label-for="AssignedTo"
                                                >
                                                    <v-select
                                                        v-model="task.AssignedTo"
                                                        multiple
                                                        label="title"
                                                        :options="assignToFilter"
                                                    />

                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                cols="12"
                                                md="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Followers`)"
                                                    label-for="Followers"
                                                >
                                                    <v-select
                                                        v-model="task.Followers"
                                                        multiple
                                                        label="title"
                                                        :options="followersFilter"
                                                    />

                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group
                                                    :label="i18nT(`Task type`)"
                                                    label-for="Type"
                                                    class="required"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Task type`)"
                                                        rules="required"
                                                    >
                                                        <v-select
                                                            v-model="task.Type"
                                                            label="text"
                                                            :options="taskTypes"
                                                            :clearable="false"
                                                            :reduce="(e) => e.value"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group
                                                    :label="i18nT(`Put in task groups`)"
                                                    label-for="TaskGroups"
                                                >
                                                    <v-select
                                                        v-model="task.TaskGroups"
                                                        multiple
                                                        label="title"
                                                        :options="taskGroups"
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group
                                                    :label="i18nT(`Due`)"
                                                    label-for="Due"
                                                >
                                                    <v-select
                                                        v-model="dueType"
                                                        label="text"
                                                        :reduce="(e) => e.value"
                                                        :options="taskDues"
                                                        :clearable="false"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <!-- Field: Due -->
                                            <b-col
                                                v-if="dueType == '0'"
                                                cols="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Due date`)"
                                                    label-for="TaskDueDate"
                                                >
                                                    <validation-provider
                                                        #default="{ errors }"
                                                        :name="i18nT(`Due date`)"
                                                        rules="required"
                                                    >
                                                        <date-picker
                                                            id="example-datepicker"
                                                            v-model="task.TaskDueDate"
                                                            :state="errors.length > 0 ? false:null"
                                                        />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <!-- DUE Type = 1 -->
                                            <b-col
                                                v-if="dueType == '1'"
                                                cols="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Onboarding timeframe`)"
                                                    label-for="TaskDueDate"
                                                >
                                                    <b-form-select
                                                        v-model="task.TimeframeOn"
                                                        :options="onTimeframes"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                v-if="dueType == '2'"
                                                cols="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Offboarding timeframe`)"
                                                    label-for="TaskDueDate"
                                                >
                                                    <b-form-select
                                                        v-model="task.TimeframeOff"
                                                        :options="offTimeframes"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    label-for="schedule"
                                                >
                                                    <b-form-checkbox
                                                        name="check-button"
                                                        switch
                                                        v-model="task.Repeating"
                                                    >
                                                        {{ i18nT(`Recurrent task`) }}
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="task.Repeating">
                                            <b-col cols="6">
                                                <b-form-group
                                                    :label="i18nT(`Repeat`)"
                                                    label-for="TaskRepeated"
                                                >
                                                    <b-form-select
                                                        v-model="task.TaskRepeated"
                                                        :options="repeatOptions"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                v-if="task.TaskRepeated == '2'"
                                                cols="6"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Weekly schedule`)"
                                                    label-for="tags"
                                                >
                                                    <v-select
                                                        v-model="RepeatWorkdays"
                                                        multiple
                                                        label="title"
                                                        :options="repeatWeeklyOptions"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                v-if="task.TaskRepeated == '3'"
                                                cols="3"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Day`)"
                                                    label-for="tags"
                                                >
                                                    <v-select
                                                        v-model="RepeatMonthDays"
                                                        label="title"
                                                        :options="repeatDaysOfMonths"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                            <b-col
                                                v-if="task.TaskRepeated == '3'"
                                                cols="3"
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Monthly schedule`)"
                                                    label-for="tags"
                                                >
                                                    <v-select
                                                        v-model="RepeatMonths"
                                                        multiple
                                                        label="title"
                                                        :options="repeatMonthlyOptions"
                                                        :reduce="(e) => e.value"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>


                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Specific to software module`)"
                                                    label-for="tags"
                                                >
                                                    <v-select
                                                        v-model="Softwares"
                                                        multiple
                                                        label="title"
                                                        :options="softwares"
                                                    />
                                                </b-form-group>
                                                <b-alert
                                                    class="mt-1 mb-2"
                                                    variant="primary"
                                                    show
                                                >
                                                    <div class="alert-body">
                            <span>
                              <b-badge variant="primary">
                                {{ i18nT(`Info`) }}
                              </b-badge>
                              {{ i18nT(`This task will be visible only for the specified software module.`) }}
                            </span>
                                                    </div>
                                                </b-alert>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12">
                                                <b-form-group
                                                    :label="i18nT(`Description`)"
                                                    label-for="description"
                                                    class="mb-2"
                                                >
                                                    <quill-editor
                                                        id="description"
                                                        v-model="task.Description"
                                                        :placeholder="i18nT(`Task description`)"
                                                        :options="snowOption"
                                                    />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="border-bottom pt-2 pb-2">
                                    <!-- Field: Username -->
                                    <b-col
                                        cols="12"
                                        md="3"
                                    >
                                        <h5>
                                            {{ i18nT(`Documents`) }}
                                        </h5>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <h6 class="mb-1">{{ i18nT(`Add documents`) }}</h6>
                                        <b-button
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                            variant="outline-primary"
                                            @click="onDocumentAdd"
                                        >
                                            <feather-icon icon="PlusCircleIcon"/>
                                            {{ i18nT(`Add file`) }}
                                        </b-button>
                                        <div
                                            class="mt-1"
                                            v-if="selectedDocument.length > 0"
                                        >
                                            <div class="mb-3 mt-2">
                                                <h6 class="d-inline">{{ i18nT(`Documents list`) }}</h6>
                                                <b-button
                                                    variant="outline-danger"
                                                    class="float-right"
                                                    @click="clearSelectedDocument"
                                                >
                                                    <feather-icon icon="MinusCircleIcon"/>
                                                    {{ i18nT(`Clear all`) }}
                                                </b-button>
                                            </div>
                                            <b-list-group>
                                                <b-list-group-item
                                                    v-for="document in selectedDocument"
                                                    :key="document.Id"
                                                >
                                                    <div>
                                                        <span class="document-name">{{ document.title }}</span>
                                                        <b-button-group
                                                            size="sm"
                                                            class="document-actions-bar"
                                                        >
                                                            <b-button
                                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                                variant="outline-primary"
                                                                @click="onDocumentEdit(document)"
                                                            >
                                                                <feather-icon
                                                                    icon="Edit3Icon"
                                                                    size="16"
                                                                    style="margin: 0;"
                                                                />
                                                            </b-button>
                                                            <b-button
                                                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                                                variant="outline-primary"
                                                                @click="onDocumentRemove(document)"
                                                            >
                                                                <feather-icon
                                                                    icon="Trash2Icon"
                                                                    size="16"
                                                                    style="margin: 0;"
                                                                />
                                                            </b-button>
                                                        </b-button-group>
                                                    </div>
                                                </b-list-group-item>
                                            </b-list-group>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <div style="flex: 1"/>
                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="primary"
                                        class="mt-0 mt-md-2 ml-1 mr-1"
                                        @click.prevent="validationForm"
                                    >
                                        {{ i18nT(`Save`) }}
                                    </b-button>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <b-modal
            id="modal-1"
            :title="i18nT(`Add documents`)"
            ok-only
            :ok-title="i18nT(`Add`)"
            @ok="onDocumentSelect"
            no-close-on-backdrop
            size="lg"
        >
            <div>
                <b-tabs
                    pills
                    align="center"
                    v-model="tabIndex">

                    <b-tab>
                        <template #title>
                            <feather-icon icon="FolderIcon" /> {{i18nT(`Existing documents`)}}
                        </template>
                        <validation-observer ref="existingDocument">
                            <b-row>
                                <b-col sm="12">
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="i18nT(`document`)"
                                        rules="required"
                                        >
                                        <b-form-group
                                            :label="i18nT(`Select documents`)"
                                            class="required"
                                            label-for="existingDocument"
                                        >
                                            <v-select
                                                v-model="selectedDocumentTemp"
                                                multiple
                                                label="title"
                                                :options="documentOptions"
                                                :state="errors.length > 0 ? false:null"
                                            />
                                        </b-form-group>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                        </validation-observer>

                    </b-tab>
                    <b-tab>
                        <template #title>
                            <feather-icon icon="UploadCloudIcon" /> {{i18nT(`New document`)}}
                        </template>
                        <div>
                            <add-document-inline
                                :onDocumentCreatedOrUpdated="onDocumentCreatedOrUpdated"
                                :existingDoc="existingDoc"
                            />
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </b-modal>
    </div>

</template>

<script>
import {
    BButton,
    BAlert,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    BCardBody,
    BFormSelect,
    BModal,
    VBModal,
    BButtonGroup,
    BListGroup,
    BListGroupItem,
    BCardHeader,
    BTabs,
    BTab
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
    dictToSelectArray,
    dictToSelectArrayWithTitle
} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import router from '@/router'
import {quillEditor} from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import _ from 'lodash'
import AddDocumentInline from '../Documents/AddDocumentInline.vue'
import DatePicker from '@core/components/DatePicker.vue'

export default {
    components: {
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BCard,
        BCardBody,
        vSelect,
        BFormSelect,
        BAlert,
        BBadge,
        BFormCheckbox,
        BModal,
        BButtonGroup,
        BListGroup,
        BListGroupItem,
        ValidationProvider,
        ValidationObserver,
        quillEditor,
        BCardHeader,
        AddDocumentInline,
        BTabs,
        BTab,
        DatePicker
    },
    directives: {
        Ripple,
        'b-modal': VBModal
    },
    data() {
        const days = []
        for (let i = 0; i < 29; i++) {
            days.push({
                value: i + 1,
                title: i + 1 + ''
            })
        }

        days.push({
            value: -1,
            title: this.i18nT(`Last day of the month`)
        })
        return {
            tabIndex: 0,
            dueType: '0',
            task: {
                Template: false,
                Due: '0',
                TaskRepeated: '0',
                TaskGroups: [],
                TaskDueDate: '',
                Repeating: 0,
                Description: '',
                AssignedTo: [],
                Followers: [],
                TimeframeOn: '',
                TimeframeOff: '',
                NoTimeDepended: 0,
            },
            RepeatWorkdays: [],
            RepeatMonths: [],
            RepeatMonthDays: [],
            taskTypes: [],
            taskGroups: [],
            taskFromTemplate: false,
            isTemplate: false,
            assigneeFilter: [],
            followersList: [],
            taskDues: [],
            documentOptions: [],
            documents: [],
            selectedDocumentTemp: [],
            selectedDocument: [],
            snowOption: {
                theme: 'snow',
                placeholder: '',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'link'],
                        [{list: 'ordered'}, {list: 'bullet'}],
                        ['clean']
                    ]
                }
            },
            required,
            email,
            dueOptions: [],
            repeatOptions: [],
            repeatWeeklyOptions: [],
            repeatMonthlyOptions: [],
            repeatDaysOfMonths: [],
            Softwares: [],
            softwares: [],
            days,
            offTimeframes: [],
            onTimeframes: [],
            existingDoc: null
            // months
        }
    },
    async created() {
        this.softwares = _.values(useAuth.getSoftwares()).map(software => ({
            value: software.id,
            title: `${software.short.toUpperCase()} - ${software.title}`
        }))
        this.taskFromTemplate = router.currentRoute.params.templateId ? true : false

        this.task.Template = router.currentRoute.meta.template === 1 && !this.taskFromTemplate

        this.isTemplate = this.task.Template

        Promise.all([
            new Promise(res => {
                this.$http.get(`tasks/taskTypes`).then(({data}) => {
                    const types = data.data.map(type => ({
                        value: type.Id,
                        text: type.Label
                    }))

                    this.taskTypes = types
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/taskGroups`).then(({data}) => {
                    const taskGroups = data.data.task_groups.map(type => ({
                        value: type.Id,
                        title: type.Label
                    }))

                    this.taskGroups = taskGroups
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/assignToUsersList`).then(({data}) => {
                    this.assigneeFilter = dictToSelectArrayWithTitle(data.data.users_list)
                    res()
                })
            }),

            new Promise(res => {
                this.$http.get(`tasks/followerUsersList`).then(({data}) => {
                    this.followersList = data.data.map(user => ({
                        value: user.Id,
                        title: user.Label
                    }))
                    res()
                })
            }),

            new Promise(res => {
                this.$http.get(`documents`).then(({data}) => {
                    this.documents = data.data.documents
                    this.documentOptions = data.data.documents.map(document => ({
                        value: document.Id + '',
                        title: document.Label
                    }))
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/dueOptions`).then(({data}) => {
                    this.taskDues = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/repeatOptions`).then(({data}) => {
                    this.repeatOptions = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/repeatWeekly`).then(({data}) => {
                    this.repeatWeeklyOptions = dictToSelectArrayWithTitle(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/repeatMonthly`).then(({data}) => {
                    this.repeatMonthlyOptions = dictToSelectArrayWithTitle(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`tasks/daysOfMonth`).then(({data}) => {
                    this.repeatDaysOfMonths = dictToSelectArrayWithTitle(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`system/onPipelineTimeframes`).then(({data}) => {
                    this.onTimeframes = dictToSelectArray(data.data)
                    res()
                })
            }),
            new Promise(res => {
                this.$http.get(`system/offPipelineTimeframes`).then(({data}) => {
                    this.offTimeframes = dictToSelectArray(data.data)
                    res()
                })
            })
        ]).then(() => {
            if (router.currentRoute.params.id) {
                this.fetchTask(router.currentRoute.params.id)
            } 
            else if(router.currentRoute.params.templateId) {
                this.fetchTask(router.currentRoute.params.templateId)
            }
        })
    },
    computed: {
        hasRouteId() {
            return router && router.currentRoute.params.id
        },
        followersFilter() {
            return this.followersList.filter(e => !this.task.AssignedTo || this.task.AssignedTo.findIndex(t => t.value === e.value) < 0)
        },
        assignToFilter() {
            return this.assigneeFilter.filter(e => !this.task.Followers || this.task.Followers.findIndex(t => t.value === e.value) < 0)
        }
    },
    setup() {
        return {}
    },
    methods: {
        fetchTask(id) {
            this.$http
            .get(`tasks?id=${id}`)
            .then(({data}) => {
                this.task = data.data
                console.log("Task on load: ", this.task);
                this.selectedDocument = dictToSelectArrayWithTitle(
                    data.data.TaskDocuments
                )
                this.documentOptions = this.documentOptions.filter(
                    e => this.selectedDocument.findIndex(d => d.value === e.value) < 0
                )
                this.task.TaskGroups = dictToSelectArrayWithTitle(
                    data.data.TaskGroups
                )
                
                this.task.Template = this.task.Template === 1 && !this.taskFromTemplate
                
                const assigneeIds = (this.task.AssignedTo + '').split(',')

                if(this.task.Followers.length > 0){
                    this.task.Followers = this.task.Followers.map(follower => ({
                        value: follower.Id,
                        title: follower.Label
                    }))
                }

                const assignees = this.assigneeFilter.filter(assignee =>
                    assigneeIds.includes(assignee.value)
                )
                this.task.AssignedTo = assignees
                this.task.TaskRepeated = this.task.RepeatFor + ''
                if (this.task.TimeframeOff) {
                    this.task.Due = '2'
                } else if (this.task.TimeframeOn) {
                    this.task.Due = '1'
                } else if (this.task.NoTimeDepended == 1) {
                    this.task.Due = '3'
                } else {
                    this.task.Due = '0'
                }
                this.dueType = this.task.Due;
                this.softwares = _.values(useAuth.getSoftwares()).map(software => ({
                    value: software.id,
                    title: `${software.short.toUpperCase()} - ${software.title}`
                }))
                if (this.task.Soft1 == 1) {
                    this.Softwares.push(
                        this.softwares.filter(software => software.value === 1)[0]
                    )
                }
                if (this.task.Soft2 == 1) {
                    this.Softwares.push(
                        this.softwares.filter(software => software.value === 2)[0]
                    )
                }
                if (this.task.Soft3 == 1) {
                    this.Softwares.push(
                        this.softwares.filter(software => software.value === 3)[0]
                    )
                }
                if (this.task.Soft4 == 1) {
                    this.Softwares.push(
                        this.softwares.filter(software => software.value === 4)[0]
                    )
                }
                if (this.task.Soft5 == 1) {
                    this.Softwares.push(
                        this.softwares.filter(software => software.value === 5)[0]
                    )
                }

                //console.log("This softwares", this.Softwares);

                if (this.task.RepeatFor == 2) {
                    const repeatDays = (this.task.RepeatData + '' || '').split(',')
                    repeatDays.forEach(repeatDay => {
                        this.RepeatWorkdays.push(
                            this.repeatWeeklyOptions[parseInt(repeatDay) - 1]
                        )
                    })
                } else if (this.task.RepeatFor == 3) {
                    if (this.task.DayOfMonth == 99) {
                        this.RepeatMonthDays = this.repeatDaysOfMonths[
                        this.repeatDaysOfMonths.length - 1
                            ]
                    } else {
                        this.RepeatMonthDays = this.repeatDaysOfMonths[
                        this.task.DayOfMonth - 1
                            ]
                    }

                    const repeatMonths = (this.task.RepeatData + '' || '').split(',')
                    repeatMonths.forEach(repeatMonth => {
                        this.RepeatMonths.push(
                            this.repeatMonthlyOptions.find(option => option.value === repeatMonth)
                        )
                    })
                }
            })
        },
        onDocumentCreatedOrUpdated: function ({data}, updated) {
            if (updated) {
                const idx = this.selectedDocument.findIndex(
                    e => e.value === data.data.Id
                )
                this.selectedDocument[idx].title = data.data.Label
            } else {
                this.selectedDocument.push({
                    value: data.data.Id,
                    title: data.data.Label
                })
            }

            this.$bvModal.hide('modal-1')
            this.tabIndex = 0
            this.existingDoc = null

            this.$http.get(`documents`).then(({data}) => {
                this.documents = data.data.documents
                this.documentOptions = data.data.documents
                    .map(document => ({
                        value: document.Id + '',
                        title: document.Label
                    }))
                    .filter(
                        e => this.selectedDocument.findIndex(d => d.value === e.value) < 0
                    )
            })
        },
        onDocumentSelect: function () {
            this.selectedDocument = [
                ...this.selectedDocument,
                ...this.selectedDocumentTemp
            ]
            this.$bvModal.hide('modal-1')
        },
        onDocumentAdd: function () {
            this.$bvModal.show('modal-1')
            this.tabIndex = 0
            this.existingDoc = null
            this.selectedDocumentTemp = []
        },
        onDocumentEdit: function (val) {
            this.selectedDocumentTemp = []
            this.existingDoc = this.documents.find(
                document => document.Id === parseInt(val.value)
            )
            this.tabIndex = 1
            this.$bvModal.show('modal-1')
        },
        onDocumentRemove: function (val) {
            this.selectedDocument = this.selectedDocument.filter(
                document => document.value != val.value
            )
            this.documentOptions = this.documents
                .map(document => ({
                    value: document.Id + '',
                    title: document.Label
                }))
                .filter(
                    e => this.selectedDocument.findIndex(d => d.value === e.value) < 0
                )
        },
        clearSelectedDocument: function () {
            this.selectedDocument = []
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    const formData = new FormData()
                    formData.append('Label', this.task.Label)
                    formData.append('Type', this.task.Type)
                    formData.append('description', this.task.Description)
                    formData.append('template', this.task.Template ? 1 : 0)
                    if(this.task.Followers.length > 0){
                        formData.append('followers', this.task.Followers.map(follower => follower.value).join(','))
                    }
                    formData.append('repeating', this.task.Repeating)
                    //console.log("Repeating on save: ", this.task.Repeating);

                    formData.append(
                        'task_groups',
                        this.task.TaskGroups.map(taskGroup => taskGroup.value).join(',')
                    )
                    formData.append(
                        'task_documents',
                        this.selectedDocument.map(document => document.value).join(',')
                    )


                    formData.append(
                        'assigned_softs',
                        this.Softwares.map(document => document.value).join(',')
                    )


                    formData.append('switch_to', this.task.Due)

                    if(this.task.AssignedTo.length > 0){
                        formData.append('assigned_to', this.task.AssignedTo.map(assignee => assignee.value).join(','))
                    }

                    if (this.task.Due === '0') {
                        formData.append('dueDate', this.task.TaskDueDate)

                    } else if (this.task.Due === '1') {
                        formData.append('timeframeOn', this.task.TimeframeOn)
                    } else if (this.task.Due === '2') {
                        formData.append('timeframeOff', this.task.TimeframeOff)
                    }

                    if(this.task.Repeating){

                        formData.append('repeat_for', parseInt(this.task.TaskRepeated))
                        if (this.task.TaskRepeated == '2') {
                            formData.append(
                                'repeat_weekly',
                                this.RepeatWorkdays.map(workday => workday.value).join(',')
                            )
                        } else if (this.task.TaskRepeated == '3') {
                            formData.append(
                                'repeat_monthly',
                                this.RepeatMonths.join(',')
                            )
                            formData.append('day_of_month', this.RepeatMonthDays.value)
                        }
                    }

                    if (router.currentRoute.params.id && !this.taskFromTemplate) {
                        formData.append('id', router.currentRoute.params.id)
                    }

                    this.$http
                        .post('tasks', formData)
                        .then(() => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Task ${this.task.Template ? 'template' : ''} ${
                                        router.currentRoute.params.id ? 'Updated' : 'Created'
                                    }`,
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })

                            router.push({
                                name:
                                    router.currentRoute.meta.template === 1
                                        ? 'task-templates'
                                        : 'tasks'
                            })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
                else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.i18nT(`Please fill all required fields`),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                }
            })
        },
    },
    watch: {
        dueType: function (val) {
            this.task.Due = val;
        },
        repeatMonthlyOptions: function (val){
            if(val[0].value !== '99') {
                let everyMonth = {...val[12]};
                this.repeatMonthlyOptions.splice(12, 1);
                this.repeatMonthlyOptions.unshift(everyMonth);
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}
</style>
